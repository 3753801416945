.header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 10vh;
  margin-top: 2vh;
}

.title {
  color: #282c34;
}