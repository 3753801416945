.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 20vh;
}

.report {
  margin-bottom: 10px;
  /* padding: 10px; */
  /* border: 1px solid #bdbdbd; */
  /* background-color: #eee; */
  text-align: left;
  width: 100%;
  /* display: block; */
  /* display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: left; */
  margin-top: 40px;
}

.reportTitle {
  color: #666666;
  text-align: left;
}

.reportContainer {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  /* padding: 20px; */
  border-width: 2px;
  border-radius: 2px;
  border-color: #bdbdbd;
  border-style: solid;
  /* background-color: #fafafa; */
  /* color: #bdbdbd; */
  outline: none;
  transition: border 0.24s ease-in-out;
  margin-top: 20px;
}

.reportInner {
  width: 100%;
}
.reportLoader {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 80px;
}
.reportLoader p {
  margin-top: 10px;
  color: #666666;
}
.filelist {
  text-decoration: none;
  list-style-type: none;
  width: 100%;
  padding-inline-start: 0px;
  margin: 0;
  padding: 10px 10px 0 10px;
}

.file {
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid #bdbdbd;
  background-color: #eee;
  text-align: left;
  width: 100%;
  /* display: block; */
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: left;
}

.file span {
  font-size: 1.2em;
  text-decoration: none;
  color: #333333;
  margin-left: 10px;
  padding: 10px;
  
}
.fileClick {
  cursor: pointer;
}

.reportlist {
  text-decoration: none;
  list-style-type: none;
  width: 100%;
  padding-inline-start: 0px;
  margin-bottom: 20px;
  padding: 10px 10px 0 10px;
}
